import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Button } from 'react-bootstrap';
import Helmet from 'react-helmet';

function CollaborationPreview({ data, isValidated, onBack, onSubmit }) {
    const renderMedia = (item, index) => {
        if (item.contentType?.startsWith('video')) {
            return <VideoPlayer key={index} videoUrl={item.url} />;
        }

        return <img key={index} src={item.url} alt={`media ${index + 1}`} />;
    };

    return (
        <>
            <Helmet>
                <title>{`${data?.userRef?.fullName} Collaboration - Admin YoRipe`}</title>
                <meta name='title' content={`${data?.userRef?.fullName} Collaboration - Admin YoRipe`} />
                <meta name='description' content='Preview collaboration post before publishing' />
            </Helmet>
            <div className='page-wrapper'>
                <div className='challenge-wrapper'>
                    <div className='challenge-header'>Preview Collaboration Post</div>

                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Caption</div>
                        <p className='challenge-section-caption'>{data?.caption}</p>
                    </div>

                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Media</div>
                        <div className='image-grid-container'>
                            {data?.media?.map(renderMedia)}
                        </div>
                    </div>

                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Creator</div>
                        <p className='challenge-section-content'>
                            {data?.userRef?.fullName} (@{data?.userRef?.account?.userName})
                        </p>
                    </div>

                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Collaboration Details</div>
                        <p className='challenge-section-content'>
                            <strong>Country:</strong> {data?.collaboration?.country.join(', ')}<br />
                            <strong>Kind:</strong> {data?.collaboration?.kind}<br />
                            <strong>Incentive:</strong> {data?.collaboration?.incentive ? 'Yes' : 'No'}<br />
                            <strong>Request Count:</strong> {data?.collaboration?.requestCount}
                        </p>
                    </div>

                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Reasons</div>
                        <p className='challenge-section-caption'>
                            {data?.collaboration?.reasons?.join(', ') || 'null'}
                        </p>
                    </div>

                    <div className='challenge-footer'>
                        <Button
                            variant='brand-red mx-2'
                            onClick={onSubmit}
                            disabled={!isValidated}>
                            Publish Collaboration
                        </Button>
                        <Button
                            variant='brand-gray mx-2'
                            onClick={onBack}>
                            Edit Form
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

CollaborationPreview.propTypes = {
    data: PropTypes.shape({
        caption: PropTypes.string,
        media: PropTypes.arrayOf(PropTypes.shape({
            url: PropTypes.string,
            contentType: PropTypes.string
        })),
        status: PropTypes.string,
        userRef: PropTypes.shape({
            fullName: PropTypes.string,
            account: PropTypes.shape({
                userName: PropTypes.string
            })
        }),
        collaboration: PropTypes.shape({
            country: PropTypes.arrayOf(PropTypes.string),
            kind: PropTypes.string,
            incentive: PropTypes.bool,
            requestCount: PropTypes.number,
            reasons: PropTypes.arrayOf(PropTypes.string)
        })
    }).isRequired,
    isValidated: PropTypes.bool.isRequired,
    onBack: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default memo(CollaborationPreview);
