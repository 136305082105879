import { getCollaborationById } from 'api/collaboration';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

function Collaboration() {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await getCollaborationById(id);

            if (response) {
                setData(response);
            }
        } catch {
            toast.error('Unable to fetch Collaboration', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } finally {
            setIsLoading(false);
        }
    }, [id]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const renderMedia = (item, index) => {
        if (item.contentType.startsWith('video')) {
            return <VideoPlayer key={index} videoUrl={item.url} />;
        }

        return <img key={index} src={item.url} alt={`media ${index + 1}`} />;
    };

    return (
        <>
            <Helmet>
                <title>Collaboration - Admin YoRipe</title>
                <meta name='title' content='Collaboration - Admin YoRipe' />
                <meta name='description' content={'Collaboration page to manage all existing collaborations'} />
            </Helmet>
            <div className='page-wrapper'>
                <div className='challenge-wrapper'>
                    <div className='challenge-header'>View Collaboration</div>
                    {isLoading && <div className='challenge-header'><Spinner animation='border' variant='red-1' /></div>}
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Caption</div>
                        <p className='challenge-section-caption'>{data?.caption}</p> {/* update to rich caption later */}
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Media</div>
                        <div className='image-grid-container'>
                            {data?.media?.map(renderMedia)}
                        </div>
                    </div>

                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Status</div>
                        <p className='challenge-section-content'>{data?.status}</p>
                    </div>

                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Creator</div>
                        <p className='challenge-section-content'>
                            {data?.userRef?.fullName} (@{data?.userRef?.account?.userName})
                        </p>
                    </div>

                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Collaboration Details</div>
                        <p className='challenge-section-content'>
                            <strong>Country:</strong> {data?.collaboration?.country.join(', ')}<br />
                            <strong>Kind:</strong> {data?.collaboration?.kind}<br />
                            <strong>Incentive:</strong> {data?.collaboration?.incentive ? 'Yes' : 'No'}<br />
                            <strong>Request Count:</strong> {data?.collaboration?.requestCount}
                        </p>
                    </div>

                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Reasons</div>
                        <p className='challenge-section-caption'>
                            {data?.collaboration?.reasons?.join(', ') || 'null'}
                        </p>
                    </div>

                    <div className='challenge-footer'>
                        <Link to={`/collaboration-edit/${data?._id}`}>
                            <Button variant='brand-red mx-2'>Edit Collaboration</Button>
                        </Link>
                        <Button variant='brand-gray mx-2' onClick={history.goBack}>Back</Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default memo(Collaboration);
