import './leftNav.scss';

import ROUTE_PATHS from 'config/routePaths';
import BannerRed from 'images/icons/Banner-red.svg';
import BannerWhite from 'images/icons/Banner-white.svg';
import BrandRed from 'images/icons/Brand-red.svg';
import BrandWhite from 'images/icons/Brand-white.svg';
import ChallengeRed from 'images/icons/Challenge-red.svg';
import ChallengeWhite from 'images/icons/Challenge-white.svg';
import ChevronLeft from 'images/icons/Chevron-white-left.svg';
import ChevronRight from 'images/icons/Chevron-white-right.svg';
import CollectionRed from 'images/icons/Collection-red.svg';
import CollectionWhite from 'images/icons/Collection-white.svg';
import EventRed from 'images/icons/Event-red.svg';
import EventWhite from 'images/icons/Event-white.svg';
import ProductRed from 'images/icons/Product-red.svg';
import ProductWhite from 'images/icons/Product-white.svg';
import RecipeRed from 'images/icons/Recipe-red.svg';
import RecipeWhite from 'images/icons/Recipe-white.svg';
import ReviewRed from 'images/icons/Review-red.svg';
import ReviewWhite from 'images/icons/Review-white.svg';
import StoryRed from 'images/icons/Story-red.svg';
import StoryWhite from 'images/icons/Story-white.svg';
import UserRed from 'images/icons/User-red.svg';
import UserWhite from 'images/icons/User-white.svg';
import YoripeLogo from 'images/YoripeLogo.svg';
import YoripeText from 'images/YoRipeWhite.svg';
import { memo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

function LeftNav() {
    const [expand, setExpand] = useState(false);
    const location = useLocation();

    const toggleExpand = () => {
        setExpand(prev => !prev);
    };

    return (
        <div className={`leftnav-container pretty-scroll unselectable ${expand ? 'leftnav-container-active' : ''}`}>
            <div className='yoripe-logo-container'>
                <img src={YoripeLogo} alt='YoRipe' className='yoripe-icon' />
                <img src={YoripeText} alt='YoRipe' className='yoripe-text' />
            </div>
            <div onClick={toggleExpand} className='expand-button shrink-none' >
                <img src={expand ? ChevronLeft : ChevronRight} alt='Expand Menu' className='left-nav-icon' />
            </div>
            <Link to={ROUTE_PATHS.RECIPES} className={location.pathname.startsWith(ROUTE_PATHS.RECIPES) ? 'nav-button-active' : 'nav-button tooltip-icon-recipe'} >
                <img src={location.pathname.startsWith(ROUTE_PATHS.RECIPES) ? RecipeRed : RecipeWhite} alt='Recipes Icon' className='left-nav-icon' />
                <span className={`nav-icon-text ${location.pathname.startsWith(ROUTE_PATHS.RECIPES) ? 'color-brand-imp' : ''}`}>Recipes</span>
            </Link>
            <Link to={ROUTE_PATHS.COOKING_STORY} className={location.pathname.startsWith(ROUTE_PATHS.COOKING_STORY) ? 'nav-button-active' : 'nav-button tooltip-icon-story'} >
                <img src={location.pathname.startsWith(ROUTE_PATHS.COOKING_STORY) ? StoryRed : StoryWhite} alt='Story Icon' className='left-nav-icon' />
                <span className={`nav-icon-text ${location.pathname.startsWith(ROUTE_PATHS.COOKING_STORY) ? 'color-brand-imp' : ''}`}>Cooking Story</span>
            </Link>
            <Link to={ROUTE_PATHS.EVENTS} className={location.pathname.startsWith(ROUTE_PATHS.EVENTS) ? 'nav-button-active' : 'nav-button tooltip-icon-event'} >
                <img src={location.pathname.startsWith(ROUTE_PATHS.EVENTS) ? EventRed : EventWhite} alt='Story Icon' className='left-nav-icon' />
                <span className={`nav-icon-text ${location.pathname.startsWith(ROUTE_PATHS.EVENTS) ? 'color-brand-imp' : ''}`}>Events</span>
            </Link>
            <Link to={ROUTE_PATHS.CHALLENGE} className={location.pathname.startsWith(ROUTE_PATHS.CHALLENGE) ? 'nav-button-active' : 'nav-button tooltip-icon-challenge'} >
                <img src={location.pathname.startsWith(ROUTE_PATHS.CHALLENGE) ? ChallengeRed : ChallengeWhite} alt='Challenge Icon' className='left-nav-icon' />
                <span className={`nav-icon-text ${location.pathname.startsWith(ROUTE_PATHS.CHALLENGE) ? 'color-brand-imp' : ''}`}>Challenge</span>
            </Link>
            <Link to={ROUTE_PATHS.BRAND_PORTAL} className={location.pathname.startsWith(ROUTE_PATHS.BRAND_PORTAL) ? 'nav-button-active' : 'nav-button tooltip-icon-brand'} >
                <img src={location.pathname.startsWith(ROUTE_PATHS.BRAND_PORTAL) ? BrandRed : BrandWhite} alt='Brand portal Icon' className='left-nav-icon' />
                <span className={`nav-icon-text ${location.pathname.startsWith(ROUTE_PATHS.BRAND_PORTAL) ? 'color-brand-imp' : ''}`}>Brand Portal</span>
            </Link>
            <Link to={ROUTE_PATHS.PRODUCTS} className={location.pathname.startsWith(ROUTE_PATHS.PRODUCTS) ? 'nav-button-active' : 'nav-button tooltip-icon-product'} >
                <img src={location.pathname.startsWith(ROUTE_PATHS.PRODUCTS) ? ProductRed : ProductWhite} alt='Products Icon' className='left-nav-icon' />
                <span className={`nav-icon-text ${location.pathname.startsWith(ROUTE_PATHS.PRODUCTS) ? 'color-brand-imp' : ''}`}>Product</span>
            </Link>
            <Link to={ROUTE_PATHS.BANNER} className={location.pathname.startsWith(ROUTE_PATHS.BANNER) ? 'nav-button-active' : 'nav-button tooltip-icon-banner'} >
                <img src={location.pathname.startsWith(ROUTE_PATHS.BANNER) ? BannerRed : BannerWhite} alt='Banner Icon' className='left-nav-icon' />
                <span className={`nav-icon-text ${location.pathname.startsWith(ROUTE_PATHS.BANNER) ? 'color-brand-imp' : ''}`}>Banner</span>
            </Link>
            <Link to={ROUTE_PATHS.TOP_COLLECTION} className={location.pathname.startsWith(ROUTE_PATHS.TOP_COLLECTION) ? 'nav-button-active' : 'nav-button tooltip-icon-collection'} >
                <img src={location.pathname.startsWith(ROUTE_PATHS.TOP_COLLECTION) ? CollectionRed : CollectionWhite} alt='Top Collection Icon' className='left-nav-icon' />
                <span className={`nav-icon-text ${location.pathname.startsWith(ROUTE_PATHS.TOP_COLLECTION) ? 'color-brand-imp' : ''}`}>Top Collection</span>
            </Link>
            <Link to={ROUTE_PATHS.USERS} className={location.pathname.startsWith(ROUTE_PATHS.USERS) ? 'nav-button-active' : 'nav-button tooltip-icon-collection'} >
                <img src={location.pathname.startsWith(ROUTE_PATHS.USERS) ? UserRed : UserWhite} alt='Users Icon' className='left-nav-icon' />
                <span className={`nav-icon-text ${location.pathname.startsWith(ROUTE_PATHS.USERS) ? 'color-brand-imp' : ''}`}>Users</span>
            </Link>
            <Link to={ROUTE_PATHS.COLLABORATION} className={location.pathname.startsWith(ROUTE_PATHS.COLLABORATION) ? 'nav-button-active' : 'nav-button tooltip-icon-collection'} >
                <img src={location.pathname.startsWith(ROUTE_PATHS.COLLABORATION) ? ReviewRed : ReviewWhite} alt='Collaboration Icon' className='left-nav-icon' />
                <span className={`nav-icon-text ${location.pathname.startsWith(ROUTE_PATHS.COLLABORATION) ? 'color-brand-imp' : ''}`}>Collaboration</span>
            </Link>
        </div>
    );
}

export default memo(LeftNav);
