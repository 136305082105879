import { deleteCollaboration, getAllCollaborations } from 'api/collaboration';
import ConfirmPopup from 'components/ConfirmPopup';
import ROUTE_PATHS from 'config/routePaths';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Spinner, Table } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { BiSearch } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';

function CollaborationScreen() {
    const [searchData, setSearchData] = useState({ page: 1, isLoading: false, data: [] });
    const [selectedItems, setSelectedItems] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const history = useHistory();

    const visiblePages = useMemo(() => {
        const startPages = searchData?.currentPage > 2 ? [1, searchData?.currentPage - 1] : [1];
        const endPages = (searchData?.lastPage - searchData?.currentPage) > 1 ? [searchData?.currentPage + 1, searchData?.lastPage] : [searchData?.lastPage];

        if (searchData?.lastPage < 4) {
            return [...Array.from({ length: searchData?.lastPage }, (_, i) => i + 1)];
        } else {
            return [...startPages, searchData?.currentPage, ...endPages];
        }
    }, [searchData?.currentPage, searchData?.lastPage]);

    const deleteCallback = useCallback((ids) => {
        const newArr = searchData.data.filter(item => !ids.includes(item._id));

        setSearchData((prev) => ({ ...prev, data: newArr }));
        setSelectedItems([]);
        setShowModal(false);
    }, [searchData]);

    const deleteHandler = useCallback(() => {
        const data = searchData.data.filter((item, index) => {
            return selectedItems.includes(index);
        });

        const ids = data.map(item => item._id);

        deleteCollaboration(ids, () => deleteCallback(ids));
    }, [deleteCallback, searchData, selectedItems]);

    const selectHandler = (index) => {
        if (selectedItems.includes(index)) {
            const filtered = selectedItems.filter((item) => item !== index);

            setSelectedItems([...filtered]);
        } else {
            setSelectedItems((prev) => [...prev, index]);
        }
    };

    useEffect(() => {
        fetchDataSearch(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchDataSearch = useCallback(async (page) => {
        try {
            setSearchData((prev) => ({ ...prev, isLoading: true }));

            const { data, totalPages } = await getAllCollaborations(page, 10);

            setSearchData({
                currentPage: page,
                lastPage: totalPages,
                isLoading: false,
                data: [...data]
            });
            setSelectedItems([]);
        } catch {
            setSearchData((prev) => ({ ...prev, isLoading: false }));
        }
    }, []);

    const renderData = (item, index) => {
        const openItem = () => {
            history.push(`/collaboration/${item._id}`);
        };

        return (
            <tr onClick={openItem} key={item._id}>
                <td><input onClick={(e) => e.stopPropagation()} type='checkbox' checked={selectedItems.includes(index)}
                    onChange={() => selectHandler(index)} /></td>
                <td><Link to={`/collaboration/${item._id}`} className='recipe-name'>{item.caption}</Link></td>
                <td>{item.userRef.fullName}</td>
                <td>{item.media?.length || 0}</td>
                <td>{item.collaboration.kind}</td>
                <td>{item.collaboration.country.join(', ')}</td>
                <td>{item.status}</td>
            </tr>
        );
    };

    const pageSelectHandler = useCallback((page) => {
        fetchDataSearch(page);
    }, [fetchDataSearch]);

    const nextPageHandler = useCallback(() => {
        if (searchData.currentPage < searchData.lastPage) {
            fetchDataSearch(searchData.currentPage + 1);
        }
    }, [fetchDataSearch, searchData.currentPage, searchData.lastPage]);

    const prevPageHandler = useCallback(() => {
        if (searchData.currentPage > 1) {
            fetchDataSearch(searchData.currentPage - 1);
        }
    }, [fetchDataSearch, searchData.currentPage]);

    const renderPages = useCallback(() => {
        if (searchData?.lastPage) {
            const pages = Array.from({ length: searchData?.lastPage }, (_, i) => i + 1);

            return (
                <>{pages.map(item => {
                    if (visiblePages.includes(item)) {
                        return <Button key={item} variant={searchData.currentPage === item ? 'red-hollow' : 'gray_hollow'} onClick={() => pageSelectHandler(item)}>{item}</Button>;
                    } else if (searchData?.currentPage - 2 === item || searchData.currentPage + 2 === item) {
                        return <span key={item} className='gray_hollow'>...</span>;
                    }

                    return null;
                })}</>
            );
        }
    }, [pageSelectHandler, searchData.currentPage, searchData?.lastPage, visiblePages]);

    return (
        <>
            <Helmet>
                <title>Admin Collaborations - YoRipe</title>
                <meta name='title' content='Admin Collaborations - YoRipe' />
                <meta name='description' content={'Admin page to manage all existing Collaborations!'} />
            </Helmet>
            <div className='page-wrapper'>
                <div className='challenges-header'>
                    <div className='search-input-wrapper'>
                        <input placeholder='Search' />
                        <BiSearch color='#818181' size={20} />
                    </div>
                    <Link to={ROUTE_PATHS.COLLABORATION_CREATE} className='no-link challenges-header-create hoverable'>+ Add Collaboration</Link>
                </div>
                <div className='data-actions-wrapper'>
                    <Button variant='danger' onClick={() => setShowModal(true)} disabled={selectedItems.length < 1}>Delete</Button>
                </div>
                {searchData.isLoading && <div className='data-actions-wrapper'><Spinner animation='border' variant='red-1' /></div>}
                <div className='challenge-data-wrapper'>
                    <Table bordered={false} hover={true}>
                        <thead>
                            <tr>
                                <th/>
                                <th>Caption</th>
                                <th>Creator</th>
                                <th>Media Count</th>
                                <th>Kind</th>
                                <th>Country</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchData.data.map(renderData)}
                        </tbody>
                    </Table>
                </div>
                <div className='data-table-footer'>
                    <div className='page-info'>Showing ({10 * (searchData.currentPage - 1) + 1} to {10 * (searchData.currentPage - 1) + searchData.data.length}) - Page {searchData.currentPage} out of {searchData.lastPage}</div>
                    <div className='pagination-wrapper'>
                        <Button variant='red-hollow' disabled={searchData.currentPage === 1} onClick={prevPageHandler}>Prev</Button>{' '}
                        {renderPages()}
                        <Button variant='red-hollow' disabled={searchData.currentPage === searchData.lastPage} onClick={nextPageHandler}>Next</Button>
                    </div>
                </div>
                <ConfirmPopup
                    isVisible={showModal}
                    onClose={() => setShowModal(false)}
                    onConfirm={deleteHandler}
                    confirmTitle='Delete'
                    title='Delete confirmation'
                    description='Items once deleted cannot be recovered! Are you sure you want to delete?'
                    type={1} />
            </div></>
    );
}

export default memo(CollaborationScreen);
