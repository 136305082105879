import { AdminApi } from './api';

/**
 * Get all collaborations with pagination
 * @param {Number} page
 * @param {Number} perPage
 * @returns {Promise}
 */
export function getAllCollaborations(page, perPage = 15) {
    return AdminApi.get('/v1/collaboration', { params: { page, perPage } });
}

/**
 * Create new collaboration
 * @param {Object} data
 * @returns {Promise}
 */
export function createCollaboration(data) {
    return AdminApi.post('/v1/collaboration', data);
}

/**
 * Get collaboration by ID
 * @param {String} id
 * @returns {Promise}
 */
export function getCollaborationById(id) {
    return AdminApi.get(`/v1/collaboration/${id}`);
}

/**
 * Delete collaboration
 * @param {String} ids
 * @returns {Promise}
 */
export function deleteCollaboration(ids) {
    return AdminApi.delete('/v1/collaboration', { data: { ids } });
}

/**
 * Update collaboration
 * @param {String} id
 * @param {Object} data
 * @returns {Promise}
 */
export function updateCollaboration(id, data) {
    return AdminApi.put(`/v1/collaboration/${id}`, data);
}

/**
 * Search brands
 * @param {String} query
 * @param {Number} page
 * @param {Number} perPage
 * @returns {Promise}
 */
export function searchBrands(query, page = 1, perPage = 15) {
    return AdminApi.get('/v1/collaboration/brand-accounts', { params: { query, page, perPage } });
}

/**
 * Generate signed URL
 * @param {Object} data
 * @returns {Promise}
 */
export function generateSignedUrl(data) {
    return AdminApi.get('/v1/collaboration/upload-url', { params: data });
}
