import { generateSignedUrl, searchBrands } from 'api/collaboration';
import ConfirmPopup from 'components/ConfirmPopup';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';
import { AiFillEye } from 'react-icons/ai';
import { BsFillImageFill } from 'react-icons/bs';
import { MdClose, MdDelete } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

const COLLABORATION_TYPES = [
    { id: 'new_product', label: 'New product: get reviews and share about our latest products' },
    { id: 'brand_event', label: 'Brand event/ campaign: shout out on our event, campaign, special sales and contest etc.' },
    { id: 'recipe_development', label: 'Recipe development: show how to use my product and develop new ways to use it' },
    { id: 'others', label: 'Others' }
];

const COUNTRIES = [
    { id: 'sg', label: 'Singapore' },
    { id: 'id', label: 'Indonesia' },
    { id: 'my', label: 'Malaysia' }
];

const isVideo = (mimeType = '') => {
    return mimeType.includes('video');
};

function DataForm({ fields, errors, setValue, saveHandler, showPreview, isEdit }) {
    const [brandQuery, setBrandQuery] = useState('');
    const [showBrandList, setShowBrandList] = useState(false);
    const [brandsData, setBrandsData] = useState({ data: [], isLoading: false });
    const [showModal, setShowModal] = useState(false);
    const [isUploading, setUploading] = useState(false);
    const imageUploadRef = useRef();
    const history = useHistory();
    const [otherReason, setOtherReason] = useState('');

    const fetchBrands = useCallback(async (query) => {
        try {
            setBrandsData({ ...brandsData, isLoading: true });
            const response = await searchBrands(query);

            setBrandsData({ data: response.data, isLoading: false });
        } catch {
            setBrandsData({ ...brandsData, isLoading: false });
        }
    }, [brandsData]);

    useEffect(() => {
        setBrandQuery(fields.brand?.fullName || '');
        fetchBrands(brandQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const uploadMediaHandler = useCallback(async (event) => {
        const files = Array.from(event.target.files);

        if (files.length > 0) {
            try {
                setUploading(true);

                const uploadPromises = files.map(async (file) => {
                    const signedUrlResponse = await generateSignedUrl({
                        path: isVideo(file.type) ? 'uploads/videos' : 'uploads/images',
                        fileName: `${Math.random().toString(16).substring(2, 18)}_${file.name}`,
                        contentType: file.type
                    });

                    if (signedUrlResponse) {
                        await fetch(signedUrlResponse.url, {
                            method: 'PUT',
                            headers: {
                                'Content-Type': file.type
                            },
                            body: file
                        });

                        return {
                            url: signedUrlResponse.mediaUrl,
                            contentType: file.type
                        };
                    }
                });

                const uploadedMedia = await Promise.all(uploadPromises);

                // Update form with all the media URLs
                setValue('media', [...(fields?.media || []), ...uploadedMedia]);
            } catch (error) {
                console.error('Upload failed:', error);
                // Add error handling here
            } finally {
                setUploading(false);
            }
        }
    }, [fields?.media, setValue]);

    const removeMediaHandler = useCallback((index) => {
        const updatedMedia = [...fields.media];

        updatedMedia.splice(index, 1);
        setValue('media', updatedMedia);
    }, [fields?.media, setValue]);

    const handleBrandSelect = useCallback((brand) => {
        setValue('user', {
            _id: brand._id,
            fullName: brand.fullName,
            userName: brand.userName,
            avatar: brand.avatar
        });
        setBrandQuery(brand.fullName);
        setShowBrandList(false);
    }, [setValue]);

    const handleCollaborationTypeToggle = (type) => {
        const currentTypes = fields.collaboration?.reasons || [];
        let updatedTypes;

        if (type === 'others') {
            const hasOthers = currentTypes.some(t => t.startsWith('others'));

            if (hasOthers) {
                updatedTypes = currentTypes.filter(t => !t.startsWith('others'));
                setOtherReason('');
            } else {
                const otherValue = otherReason ? `others:${otherReason}` : 'others';

                updatedTypes = [...currentTypes, otherValue];
            }
        } else {
            updatedTypes = currentTypes.includes(type)
                ? currentTypes.filter(t => t !== type)
                : [...currentTypes, type];
        }

        setValue('collaboration.reasons', updatedTypes);
    };

    const handleOtherReasonChange = (value) => {
        setOtherReason(value);
        const currentTypes = fields.collaboration?.reasons || [];

        const updatedTypes = currentTypes.map(type =>
            type.startsWith('others:') ? `others:${value}` : type
        );

        setValue('collaboration.reasons', updatedTypes);
    };

    const handleCountryToggle = (country) => {
        const currentCountries = fields.collaboration?.country || [];
        const updatedCountries = currentCountries.includes(country)
            ? currentCountries.filter(c => c !== country)
            : [...currentCountries, country];

        setValue('collaboration.country', updatedCountries);
    };

    const renderMedia = useCallback((item, index) => {
        return (
            <div key={index} className='form-image-wrapper'>
                {item.contentType.startsWith('video')
                    ? <VideoPlayer videoUrl={item.url} />
                    : <img src={item.url} className='form-image' alt={`media ${index + 1}`} />}
                <div onClick={() => removeMediaHandler(index)} className='form-input-image-close'>
                    <MdClose color='#fff' width={20} height={20} />
                </div>
            </div>
        );
    }, [removeMediaHandler]);

    return (
        <div className='form-wrapper'>
            <Form>
                <Form.Group className='mb-3'>
                    <Form.Label className='form-label'>Brand Account</Form.Label>
                    {fields.user?._id
                        ? (
                            <div className='selected-brand-wrapper mb-2'>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div className='d-flex align-items-center'>
                                        <img
                                            src={fields.user.avatar}
                                            alt={fields.user.fullName}
                                            className='brand-avatar me-2'
                                            style={{ width: '40px', height: '40px', borderRadius: '50%' }}/>
                                        <div>
                                            <div className='brand-name'>{fields.user.fullName}</div>
                                            <div className='brand-username text-muted'>@{fields.user.userName}</div>
                                        </div>
                                    </div>
                                    {!isEdit && (
                                        <button
                                            className='btn btn-link text-danger p-0'
                                            onClick={() => handleBrandSelect({
                                                _id: '',
                                                fullName: '',
                                                userName: '',
                                                avatar: ''
                                            })}>
                                            <MdDelete size={24} />
                                        </button>
                                    )}
                                </div>
                            </div>
                        )
                        : (
                            <Dropdown onToggle={setShowBrandList}>
                                <Dropdown.Toggle className='categories-wrapper'>
                                    <input
                                        value={brandQuery}
                                        onChange={(e) => {
                                            setBrandQuery(e.target.value);
                                            fetchBrands(e.target.value);
                                        }}
                                        className='organizer-input'
                                        placeholder='Search for brand'/>
                                </Dropdown.Toggle>
                                <Dropdown.Menu show={showBrandList}>
                                    {brandsData.data.map(brand => (
                                        <Dropdown.Item
                                            key={brand.id}
                                            onClick={() => handleBrandSelect(brand)}>
                                            {brand.fullName}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                    {errors?.brand?.message && (
                        <Form.Text className='form-error-message'>{errors.brand.message}</Form.Text>
                    )}
                </Form.Group>

                <Form.Group className='mb-3'>
                    <Form.Label className='form-label'>Caption</Form.Label>
                    <textarea
                        value={fields?.caption}
                        onChange={(e) => setValue('caption', e.target.value)}
                        className='form-textarea'
                        rows={4}
                        placeholder='Write your collaboration post caption'/>
                    {errors?.caption?.message && (
                        <Form.Text className='form-error-message'>{errors.caption.message}</Form.Text>
                    )}
                </Form.Group>

                <Form.Group className='mb-3'>
                    <Form.Label className='form-label'>Media <span className='form-label-info'>(Upto 10 files)</span></Form.Label>
                    {fields?.media?.map(renderMedia)}
                    <div
                        onClick={() => imageUploadRef.current.click()}
                        className={`image-placeholder-wrapper hoverable ${isUploading ? 'disabled' : ''}`}>
                        <BsFillImageFill color='#bdbdbd' size={40} />
                    </div>
                    <input
                        type='file'
                        multiple={true}
                        ref={imageUploadRef}
                        accept='image/*,video/*'
                        style={{ display: 'none' }}
                        onChange={uploadMediaHandler}/>
                    {errors?.media?.message && (
                        <Form.Text className='form-error-message'>{errors.media.message}</Form.Text>
                    )}
                </Form.Group>

                <Form.Group className='mb-3'>
                    <Form.Label className='form-label'>Collaboration Type</Form.Label>
                    {COLLABORATION_TYPES.slice(0, -1).map(type => (
                        <Form.Check
                            key={type.id}
                            type='checkbox'
                            label={type.label}
                            checked={fields.collaboration?.reasons?.includes(type.label)}
                            onChange={() => handleCollaborationTypeToggle(type.label)}/>
                    ))}
                    <div className='d-flex flex-column gap-2'>
                        <Form.Check
                            type='checkbox'
                            label='Others'
                            checked={fields.collaboration?.reasons?.some(t => t.startsWith('others'))}
                            onChange={() => handleCollaborationTypeToggle('others')}/>
                        {fields.collaboration?.reasons?.some(t => t.startsWith('others')) && (
                            <Form.Control
                                as='textarea'
                                rows={3}
                                placeholder='Specify other reason'
                                value={otherReason}
                                onChange={(e) => handleOtherReasonChange(e.target.value)}
                                style={{ width: '100%', maxWidth: '500px' }}/>
                        )}
                    </div>
                    {errors?.collaboration?.reasons?.message && (
                        <Form.Text className='form-error-message'>
                            {errors.collaboration.reasons.message}
                        </Form.Text>
                    )}
                </Form.Group>

                <Form.Group className='mb-3'>
                    <Form.Label className='form-label'>Collaboration Status</Form.Label>
                    <div>
                        <Form.Check
                            type='radio'
                            id='kind-open'
                            label='Open'
                            checked={fields.collaboration?.kind === 'open'}
                            onChange={() => setValue('collaboration.kind', 'open')}/>
                        <Form.Check
                            type='radio'
                            id='kind-invite'
                            label='Invite'
                            checked={fields.collaboration?.kind === 'invite'}
                            onChange={() => setValue('collaboration.kind', 'invite')}/>
                    </div>
                    {errors?.collaboration?.kind?.message && (
                        <Form.Text className='form-error-message'>{errors.collaboration.kind.message}</Form.Text>
                    )}
                </Form.Group>

                <Form.Group className='mb-3'>
                    <Form.Label className='form-label'>Countries</Form.Label>
                    {COUNTRIES.map(country => (
                        <Form.Check
                            key={country.id}
                            type='checkbox'
                            label={country.label}
                            checked={fields.collaboration?.country?.includes(country.id)}
                            onChange={() => handleCountryToggle(country.id)}/>
                    ))}
                </Form.Group>

                <Form.Group className='mb-3'>
                    <Form.Label className='form-label'>Incentive</Form.Label>
                    <Form.Check
                        type='switch'
                        label='Enable prizes'
                        checked={fields.collaboration?.incentive}
                        onChange={(e) => setValue('collaboration.incentive', e.target.checked)}/>
                </Form.Group>
            </Form>

            <div className='form-footer'>
                <Button variant='brand-red-hollow' onClick={showPreview}>
                    <AiFillEye color='#FF7268' /> Preview
                </Button>
                <div>
                    <Button variant='brand-gray mx-2' onClick={() => setShowModal(true)}>
                        Cancel
                    </Button>
                    <Button variant='brand-red mx-2' onClick={saveHandler}>
                        Save Collaboration
                    </Button>
                </div>
            </div>

            <ConfirmPopup
                isVisible={showModal}
                onClose={() => setShowModal(false)}
                onConfirm={history.goBack}
                confirmTitle='Leave'
                title='Unsaved Changes'
                description='Are you sure want to discard this changes? Changes you made may not be saved.'
                type={1}/>
        </div>
    );
}

DataForm.propTypes = {
    fields: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    setValue: PropTypes.func.isRequired,
    saveHandler: PropTypes.func.isRequired,
    showPreview: PropTypes.func.isRequired,
    isEdit: PropTypes.bool
};

export default memo(DataForm);
