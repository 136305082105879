import { createCollaboration, getCollaborationById, updateCollaboration } from 'api/collaboration';
import CollaborationPreview from 'components/CollaborationPreview';
import React, { memo, useCallback, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import DataForm from './components/DataForm';

export const generateRichCaptionWithUndefinedHashtags = (text) =>
    text
        .replaceAll('\n', '\n ') // ensure new line will also parsed
        .split(' ')
        .map((word) => (word.startsWith('#') ? `[${word.trim()}]::[undefined]` : word))
        .join(' ')
        .replaceAll('\n ', '\n');

export const populateMentionsAndHashtags = (richCaption) => {
    // const mentionIds = [];
    const hashtagNames = [];

    richCaption
        .replaceAll('\n', '\n ')
        .split(' ')
        .forEach((x) => {
            // if (x.startsWith('[@')) {
            //     const mentionId = x.substring(x.lastIndexOf('[') + 1, x.lastIndexOf(']'));

            //     if (mentionId) mentionIds.push(mentionId);
            // }

            if (x.startsWith('[#')) {
                const hashtagName = x.substring(x.indexOf('#') + 1, x.indexOf(']'));

                if (hashtagName) hashtagNames.push(hashtagName);
            }
        });

    return { hashtagNames };
};

function CollaborationForm() {
    const [showPreview, setShowPreview] = useState(false);
    const [isValidated, setIsValidated] = useState(false);
    const [parsedData, setParsedData] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { id } = useParams();
    const history = useHistory();

    const { register, watch, setValue, clearErrors, trigger, formState: { errors } } = useForm({
        defaultValues: {
            user: {
                _id: '',
                fullName: '',
                userName: '',
                avatar: ''
            },
            caption: '',
            media: [],
            collaboration: {
                kind: 'open',
                country: [],
                incentive: false,
                reasons: []
            }
        }
    });

    const fields = watch();

    useEffect(() => {
        register('user', { required: 'User selection is required' });
        register('media', { required: 'At least one media file is required' });
        register('collaboration.kind', { required: 'Collaboration kind is required' });
        register('collaboration.country', {
            required: 'Country selection is required',
            validate: value => {
                const validCountries = ['sg', 'id', 'my'];

                return value.every(country => validCountries.includes(country)) || 'Invalid country selection';
            }
        });
        register('collaboration.reasons', { required: 'At least one reason is required' });
    }, [register]);

    const fetchData = useCallback(async () => {
        if (!id) return;

        try {
            const data = await getCollaborationById(id);

            if (data) {
                setValue('user', {
                    _id: data.userRef._id,
                    fullName: data.userRef.fullName,
                    userName: data.userRef.account?.userName,
                    avatar: data.userRef.avatar
                });
                setValue('caption', data.caption);
                setValue('media', data.media);
                setValue('collaboration', {
                    kind: data.collaboration.kind,
                    country: data.collaboration.country,
                    incentive: data.collaboration.incentive,
                    reasons: data.collaboration.reasons
                });
            }
        } catch (error) {
            toast.error('Unable to fetch collaboration details', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        }
    }, [id, setValue]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const parseCollaborationData = useCallback(() => {
        const richCaption = generateRichCaptionWithUndefinedHashtags(fields.caption);
        const { hashtagNames } = populateMentionsAndHashtags(richCaption);

        return {
            userId: fields.user._id,
            userRef: {
                _id: fields.user._id,
                fullName: fields.user.fullName,
                userName: fields.user.userName,
                avatar: fields.user.avatar
            },
            caption: fields.caption,
            generated: {
                richCaption
            },
            tagging: {
                hashtags: hashtagNames
            },
            media: fields.media,
            preview: fields.media[0],
            collaboration: {
                kind: fields.collaboration.kind,
                country: fields.collaboration.country,
                incentive: fields.collaboration.incentive,
                reasons: fields.collaboration.reasons
            }
        };
    }, [fields]);

    const previewHandler = useCallback(() => {
        setParsedData(parseCollaborationData());
        setShowPreview(true);
    }, [parseCollaborationData]);

    const saveHandler = useCallback(async () => {
        clearErrors();
        const isValid = await trigger();

        if (isValid) {
            previewHandler();
            setIsValidated(true);
        }
    }, [clearErrors, previewHandler, trigger]);

    const submitHandler = useCallback(async () => {
        try {
            setIsSubmitting(true);
            const submitData = parseCollaborationData();

            let response = { _id: id };

            if (id) {
                await updateCollaboration(id, submitData);
            } else {
                response = await createCollaboration(submitData);
            }

            toast.success(`Collaboration ${response._id ? 'updated' : 'created'} successfully`, {
                position: toast.POSITION.BOTTOM_LEFT
            });
            history.push(`/collaboration/${response._id}`);
        } catch (error) {
            toast.error(`Unable to ${id ? 'update' : 'create'} collaboration`, {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } finally {
            setIsSubmitting(false);
        }
    }, [history, id, parseCollaborationData]);

    return (
        <>
            <Helmet>
                <title>{id ? 'Edit' : 'Create'} Collaboration - YoRipe</title>
                <meta name='description' content='Create or edit collaboration posts for brands' />
            </Helmet>
            <div className={`page-wrapper ${isSubmitting ? 'disabled' : ''}`}>
                {showPreview
                    ? <CollaborationPreview
                        data={parsedData}
                        isValidated={isValidated}
                        onBack={() => setShowPreview(false)}
                        onSubmit={submitHandler}/>
                    : <DataForm
                        fields={fields}
                        errors={errors}
                        isEdit={!!id}
                        setValue={setValue}
                        saveHandler={saveHandler}
                        showPreview={previewHandler}/>
                }
            </div>
        </>
    );
}

export default memo(CollaborationForm);
